




















import Vue, { PropType } from 'vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default Vue.extend({
  name: 'SearchInput',

  props: {
    autoFocus: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    location: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data () {
    return {
      searchValue: '' as string,
      isFirstEdit: true,
    };
  },

  fetch () {
    if (this.location === 'header' || this.location === 'mobileActionsheet') {
      this.searchValue = '';
    } else {
      this.searchValue = String(this.$route?.query?.s) || '';
    }
  },

  computed: {
    icon () {
      if (this.searchValue) {
        return {
          name: 'close',
          alt: 'Очистить поле',
          value: 'clear',
        };
      } else {
        return {
          name: 'search',
          alt: 'иконка поиска',
          value: 'search',
        };
      }
    },
    iconClass (): Record<string, boolean> {
      return {
        'search-input-wrapp__icon-clear': this.icon.value === 'clear',
      };
    },
  },

  watch: {
    '$route.query.s': {
      handler (search) {
        if (this.location !== 'header' && this.location !== 'mobileActionsheet') {
          this.searchValue = search;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted () {
    if (this.autoFocus) {
      this.inputFocus();
    }
  },

  methods: {
    clickIcon () {
      if (this.searchValue) {
        this.searchValue = '';
        this.inputFocus();

        this.$sendAnalyticsEvent({
          event: 'Событие_Поиск',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: '',
          item: '',
          action: 'Очистка запроса',
          value: 1,
          currency: 'piece',
        });

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Очистка запроса',
        });
      }
    },

    inputFocus (): void {
      const inputSearch = this.$refs.inputSearch as HTMLElement;
      inputSearch.focus();
    },

    setSearchValue (value: string) {
      this.searchValue = value;

      if (this.isFirstEdit) {
        this.$sendAnalyticsEvent({
          event: 'Событие_Поиск',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: '',
          item: '',
          action: 'Ввод запроса',
          value: 1,
          currency: 'piece',
        });

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Ввод запроса',
        });
      }

      this.isFirstEdit = false;
    },

    goSearch () {
      if (this.searchValue) {
        const tabType = this.$route.params.type;

        this.$sendAnalyticsEvent({
          event: 'Событие_Поиск',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: '',
          item: '',
          action: 'Отправка запроса',
          value: 1,
          currency: 'piece',
        });

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Отправка запроса',
        });

        this.$emit('searching');

        if (window.innerWidth < 768 && this.location === 'mobileActionsheet') {
          this.$modal.hideModal('windows', 'TopHeaderSearchActionsheet');
        }

        const { href } = this.$router.resolve({
          name: 'search-archive',
          query: {
            s: this.searchValue,
            type: tabType || 'all',
          },
        });
        location.href = href;

        // костыль, после смены роута, накст не успевает поменять данные, и берет page с прошлой страницы
        setTimeout(() => {
          this.$nuxt.refresh();
        }, 0);
      }
    },

    getLanguage (): string {
      const isEnglish = /^[a-zA-Z]+$/.test(this.searchValue);

      return isEnglish ? 'английский' : 'русский';
    },
  },
});
