






import Vue from 'vue';
import SearchInput from './SearchInput.vue';
import ModalFade from '~/components/ModalFade.vue';
import { isHealthPath } from '~/router';

export default Vue.extend({
  name: 'TopHeaderSearchActionsheet',

  components: {
    SearchInput,
    ModalFade,
  },

  computed: {
    cssVariables (): string {
      return `
        --main-color-search-mobile: ${isHealthPath(this.$route.path) ? ' #2962f9' : '#ed5e42'}
      `;
    },
  },

  methods: {
    closeModal () {
      this.$modal.hideModal('windows', 'TopHeaderSearchActionsheet');
    },
  },
});
