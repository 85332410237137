











import Vue from 'vue';
import SearchInput from './SearchInput.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default Vue.extend({
  name: 'TopHeaderSearch',
  components: {
    SearchInput,
  },

  methods: {
    clickClose () {
      this.$emit('click-on-close');

      this.$sendAnalyticsEvent({
        event: 'Событие_Поиск',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: '',
        element: 'tab.title',
        item: '',
        action: 'Закрытие поиска',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Событие_Поиск',
        level6: 'Закрытие поиска',
      });
    },
  },
});
